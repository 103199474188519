<template>
    <el-form :label-width="`${Number(formConfig.info.labelWidth) + 2}em`" :class="formConfig.info.tableColumn" :model="form" :rules="rules" :disabled="disabled" ref="form">

        <template  v-for="(value, key, index) in fields">
            <slot :name="`groupName_${key}`" :value="value" :form="form" :submit="submit">
                <el-divider v-if="key" content-position="left">{{ key }}</el-divider>
            </slot>
            <template v-for="n in value" v-if="n.formIsShow">
                <slot :name="`formItem_${n.fieldName}`" :field="n" :form="form" :submit="submit">
                    <el-form-item :label="n.fieldLabel||n.fieldName" :prop="n.fieldName" :ref="n.fieldName" :style="`width:${n.itemWidth}%`" :label-width="n.labelWidth">
                        <template v-if="n.fType=='textarea'">
                            <el-input :disabled="n.isEdit=='true'" type="textarea" autosize v-model="form[n.fieldName]"></el-input>
                        </template>
                        <template v-else-if="n.fType=='inputNumber'">
                            <el-input-number :disabled="n.isEdit=='true'" v-model="form[n.fieldName]" controls-position="right"></el-input-number>
                        </template>
                        <template v-else-if="n.fType=='date'">
                            <el-date-picker :disabled="n.isEdit=='true'" value-format="yyyy-MM-dd" v-model="form[n.fieldName]" type="date"></el-date-picker>
                        </template>
                        <template v-else-if="n.fType=='time'">
                            <el-time-picker :disabled="n.isEdit=='true'" v-model="form[n.fieldName]" type="time"></el-time-picker>
                        </template>
                        <template v-else-if="n.fType=='dateTime'">
                            <el-date-picker :disabled="n.isEdit=='true'" value-format="yyyy-MM-dd hh:mm:ss" v-model="form[n.fieldName]" type="datetime"></el-date-picker>
                        </template>
                        <template v-else-if="n.fType=='dictionaries'">
                            <Dictionary :disabled="n.isEdit=='true'" v-model="form[n.fieldName]" valKey="val" :code="n.dictionariesCode"></Dictionary>
                        </template>
                        <template v-else>
                            <el-input v-model="form[n.fieldName]" :disabled="n.isEdit=='true'" :placeholder="`请输入${n.fieldLabel?n.fieldLabel:n.fieldName}`" @keyup.enter.native="submit"></el-input>
                        </template>
                    </el-form-item>
                </slot>
            </template>
        </template>

    </el-form>
</template>
<script>

export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default:function(){
                return {}
            }
        },
        submit:{
            type: Function,
            default: () => {}
        },
        formConfig: {
            type: Object,
            default:function(){
                return {
                    info:{},
                    list:[],
                }
            }
        },
    },
    inject:['formMunted'],
    data() {
        return {
            rules: {},
            fields:{},
        };
    },
    watch: {
        formConfig: {
            immediate: true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal) {
                this.fields = {};
                newVal.list.some(r=>{

                    if(r.itemWidth==0){r.itemWidth=null};

                    let key = r.groupName||'';

                    if(!this.fields[key]){
                        this.fields[key] = [];
                    }
                    var controlValueDomain = {};
                    if(r.controlValueDomain){
                        try{
                            controlValueDomain = JSON.parse(r.controlValueDomain)
                        }catch (err) {
                            //console.log(err)
                        };
                    }

                    this.fields[key].push(r.combination({controlValueDomain}))

                    let arr = [];
                    if(r.required=='true'){
                        arr.push({required: true, message: `请输入${r.fieldLabel}`, trigger: ['blur','change']});
                    }
                    if(r.rules){
                        var rules = [];
                        try{
                            rules = JSON.parse(r.rules)
                        }catch (err) {
                            console.log(err)
                        };
                        rules.some(r=>{
                            arr.push({
                                pattern:new RegExp(r.pattern),
                                message:r.message,
                                trigger:['blur','change']
                            })
                        })
                    }
                    this.rules[r.fieldName] = arr;
                });
            }
        },
    },
    created() {},
    mounted(){
        this.$emit('init', this.$refs.form)
        this.formMunted(this.$refs.form)

    },
    methods: {
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
</style>
